import { AbilityBuilder, createMongoAbility, } from '@casl/ability';
export var defineAbilityFor = function (_a) {
    var user = _a.user, entity = _a.entity;
    var _b = new AbilityBuilder(createMongoAbility), can = _b.can, build = _b.build;
    if (user.roles.includes('ROLE_ADMIN') ||
        user.id === (entity === null || entity === void 0 ? void 0 : entity.authorId) ||
        user.userName === (entity === null || entity === void 0 ? void 0 : entity.userName)) {
        can('edit', 'document');
        can('delete', 'document');
        can('edit', 'file');
        can('delete', 'file');
        can('edit', 'user');
        can('delete', 'user');
    }
    if (user.roles.includes('ROLE_ADMIN')) {
        can('edit', 'author');
        can('edit', 'role');
    }
    return build();
};
