var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetUsersQuery as getUsers } from '../../../store/usersApi';
import { CheckBox, FileInput, ButtonComponent, MultiSelectComponent, Title, } from '../../ui';
import { useUploadFileMutation } from '../../../store/filesApi';
import { closeModal } from '../../../store/modalSlice';
import { routes } from '../../../routes';
var defaultValues = {
    params: {
        available_for: [],
        public_document: false,
    },
};
export var UploadFile = function () {
    var _a;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var users = getUsers().data;
    var uploadFile = useUploadFileMutation()[0];
    var availableForOptions = (_a = users === null || users === void 0 ? void 0 : users.map(function (user) { return ({
        label: user.name,
        value: user.id,
    }); })) !== null && _a !== void 0 ? _a : [{ label: '', value: 0 }];
    var _b = useForm({ defaultValues: defaultValues }), control = _b.control, handleSubmit = _b.handleSubmit, errors = _b.formState.errors, setValue = _b.setValue;
    var onSubmit = function (data) {
        var fomrData = new FormData();
        fomrData.append('params', JSON.stringify(data.params));
        fomrData.append('file', data.file);
        uploadFile(fomrData)
            .unwrap()
            .then(function () {
            toast.success(t('files.modal.upload.toast.success'));
        })
            .catch(function () {
            toast.error(t('files.modal.upload.toast.error'));
        });
        dispatch(closeModal());
        navigate(routes.filesRoute());
    };
    return (_jsxs("form", { className: 'flex flex-col gap-3 sm:gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), children: [_jsx(Title, { children: t('files.modal.title.create') }), _jsx(Controller, { control: control, name: 'file', render: function (_a) {
                    var _b;
                    var field = _a.field;
                    return (_jsx(FileInput, __assign({}, field, { value: (_b = field.value) === null || _b === void 0 ? void 0 : _b.fileName, onChange: field.onChange, error: errors.file })));
                } }), _jsx(Controller, { control: control, name: 'params.available_for', render: function (_a) {
                    var _b;
                    var field = _a.field;
                    return (_jsx(MultiSelectComponent, __assign({}, field, { error: (_b = errors.params) === null || _b === void 0 ? void 0 : _b.available_for, label: t('files.modal.form.labels.availableFor'), onChange: field.onChange, selectOptions: availableForOptions, placeholder: t('files.modal.form.placeholders.availableFor'), required: false })));
                } }), _jsxs("div", { className: 'flex flex-col gap-5 md:flex-row justify-between items-center', children: [_jsx(Controller, { control: control, name: 'params.public_document', render: function (_a) {
                            var field = _a.field;
                            return (_jsx(CheckBox, __assign({}, field, { checked: !!field.value, label: '\u0421\u0434\u0435\u043B\u0430\u0442\u044C \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442 \u043F\u0443\u0431\u043B\u0438\u0447\u043D\u044B\u043C', onChange: function (e) {
                                    return setValue('params.public_document', e.target.checked);
                                } })));
                        } }), _jsx(ButtonComponent, { type: 'submit', variant: 'primary', children: "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0444\u0430\u0439\u043B" })] })] }));
};
