import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { ActionButton } from './ActionButton';
import { usePagination } from '../../hooks/usePagination';
export var Pagination = function (_a) {
    var numberOfPages = _a.numberOfPages, currentPage = _a.currentPage, goToPage = _a.goToPage, className = _a.className;
    var pageNumbers = usePagination({ numberOfPages: numberOfPages, currentPage: currentPage });
    var commonClassNames = 'sm:p-1 border-gray';
    var basicClassNames = 'bg-white hover:bg-whiteHover dark:bg-secondaryDark dark:hover:bg-secondaryDarkHover';
    var activePageClassNames = "text-secondary hover:text-secondary dark:text-whiteDark dark:hover:text-whiteDark\n    bg-whiteHover hover:bg-whiteHover dark:bg-secondaryDarkHover dark:hover:bg-secondaryDarkHover";
    var inactiveButtonClassNames = "text-secondaryHover hover:text-secondaryHover\n    dark:text-whiteDarkHover dark:hover:text-whiteDarkHover\n    bg-white hover:bg-white dark:bg-secondaryDark dark:hover:bg-secondaryDark";
    return (_jsxs("div", { className: clsx(className, 'flex rounded-md overflow-hidden shadow-md'), children: [_jsx(ActionButton, { actionType: 'chevronDouble', className: clsx(commonClassNames, currentPage === 1 ? inactiveButtonClassNames : basicClassNames, 'border-l'), mirrored: true, onClick: function () { return goToPage(1); }, disabled: currentPage === 1 }), _jsx(ActionButton, { actionType: 'chevronSingle', className: clsx(commonClassNames, currentPage === 1 ? inactiveButtonClassNames : basicClassNames, 'border-l'), mirrored: true, onClick: function () { return goToPage(currentPage - 1); }, disabled: currentPage === 1 }), pageNumbers.map(function (number) { return (_jsx(ActionButton, { actionType: 'character', className: clsx(commonClassNames, number === currentPage ? activePageClassNames : basicClassNames, 'border-r w-8'), disabled: number === currentPage, onClick: function () { return goToPage(number); }, children: number }, number)); }), _jsx(ActionButton, { actionType: 'chevronSingle', className: clsx(commonClassNames, currentPage === numberOfPages
                    ? inactiveButtonClassNames
                    : basicClassNames, 'border-r'), onClick: function () { return goToPage(currentPage + 1); }, disabled: currentPage === numberOfPages }), _jsx(ActionButton, { actionType: 'chevronDouble', className: clsx(commonClassNames, currentPage === numberOfPages
                    ? inactiveButtonClassNames
                    : basicClassNames), onClick: function () { return goToPage(numberOfPages); }, disabled: currentPage === numberOfPages })] }));
};
