import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { DropDown } from '../ui';
export var LanguageSwitcher = function () {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var handleChangeLanguage = function (language) {
        if (language === i18n.language)
            return;
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    };
    var languages = {
        ru: t('header.russian'),
        en: t('header.english'),
    };
    return (_jsx(DropDown, { options: languages, name: languages[i18n.language], action: handleChangeLanguage }));
};
