var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
export var ButtonComponent = function (_a) {
    var variant = _a.variant, className = _a.className, children = _a.children, props = __rest(_a, ["variant", "className", "children"]);
    var cn = clsx(className, 'transition-colors', 'rounded-sm', 'p-2', 'leading-none', 'h-[34px]', 'sm:h-[38px]', 'md:h-[42px]', 'cursor-pointer', 'box-border', 'text-nowrap', {
        primary: "bg-secondary hover:bg-secondaryHover\n        dark:bg-secondaryDark dark:hover:bg-secondaryDarkHover\n        text-white dark:text-whiteDark\n        dark:border dark:border-whiteDark",
        outline: "border-2 border-secondary bg-white text-secondary hover:bg-whiteHover\n        dark:border-whiteDark dark:hover:border-whiteDarkHover\n        dark:bg-whiteDark dark:text-primaryDark dark:hover:bg-whiteDarkHover",
        danger: "bg-danger hover:bg-dangerHover text-white \n        dark:bg-dangerDark dark:text-whiteDark",
        borderLess: "bg-white text-secondary hover:bg-whiteHover !rounded-none\n      dark:bg-whiteDark dark:text-primaryDark dark:hover:bg-whiteDarkHover",
    }[variant]);
    return (_jsx("button", __assign({ className: cn }, props, { children: children })));
};
