var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Can } from '@casl/react';
import { InputField, SelectComponent, MultiSelectComponent, TextArea, CheckBox, ButtonComponent, Title, } from '../../ui';
import isEqual from 'lodash.isequal';
import { useEditDocMutation, useGetDocQuery as getDoc, } from '../../../store/docsApi';
import { useGetUsersQuery as getUsers } from '../../../store/usersApi';
import { routes } from '../../../routes';
import { closeModal, getCurrentDataId } from '../../../store/modalSlice';
import { editDocFormSchema } from './docFormSchema';
import { useAuth } from '../../../context/AuthContext';
import { defineAbilityFor } from '../../../casl/ability';
export var EditDocument = function () {
    var _a;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var id = useSelector(getCurrentDataId);
    var _b = useAuth(), currentUser = _b.currentUser, isAuthenticated = _b.isAuthenticated;
    var ability = defineAbilityFor({
        user: __assign(__assign({}, currentUser), { isAuthenticated: isAuthenticated }),
    });
    var users = getUsers().data;
    var doc = getDoc(id).data;
    var editDoc = useEditDocMutation()[0];
    var selectTypeOptions = [
        { value: 1, label: t('documents.type.NOTE') },
        { value: 2, label: t('documents.type.REPORT') },
        { value: 3, label: t('documents.type.PRESENTATION') },
        { value: 4, label: t('documents.type.ARTICLE') },
    ];
    var defaultValues = {
        title: doc === null || doc === void 0 ? void 0 : doc.title,
        number: doc === null || doc === void 0 ? void 0 : doc.number,
        content: doc === null || doc === void 0 ? void 0 : doc.content,
        authorId: doc === null || doc === void 0 ? void 0 : doc.author.idUser,
        type_id: doc === null || doc === void 0 ? void 0 : doc.type.id,
        available_for: doc === null || doc === void 0 ? void 0 : doc.available_for,
        public_document: !!(doc === null || doc === void 0 ? void 0 : doc.public_document),
    };
    var availableForOptions = (_a = users === null || users === void 0 ? void 0 : users.map(function (user) { return ({
        label: user.name,
        value: user.id,
    }); })) !== null && _a !== void 0 ? _a : [{ label: '', value: 0 }];
    var _c = useForm({
        defaultValues: defaultValues,
        resolver: zodResolver(editDocFormSchema),
    }), register = _c.register, control = _c.control, handleSubmit = _c.handleSubmit, errors = _c.formState.errors, setValue = _c.setValue;
    var onSubmit = function (data) {
        if (isEqual(data, defaultValues)) {
            dispatch(closeModal());
        }
        else {
            editDoc({ data: data, id: id })
                .unwrap()
                .then(function () {
                toast.success(t('documents.modal.edit.toast.success'));
            })
                .catch(function () {
                toast.error(t('documents.modal.edit.toast.error'));
            });
            dispatch(closeModal());
            navigate(routes.documentsRoute());
        }
    };
    return (_jsxs("form", { className: 'flex flex-col gap-3 sm:gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), children: [_jsx(Title, { children: t('documents.modal.title.edit') }), _jsx(InputField, __assign({}, register('title'), { label: t('documents.modal.form.labels.title'), error: errors.title })), _jsx(InputField, __assign({}, register('number'), { label: t('documents.modal.form.labels.number'), error: errors.number })), _jsx(Can, { I: 'edit', an: 'author', ability: ability, children: _jsx(InputField, __assign({}, register('authorId'), { label: t('documents.modal.form.labels.authorId'), error: errors.authorId })) }), _jsx(TextArea, __assign({}, register('content'), { label: t('documents.modal.form.labels.content'), error: errors.content })), _jsx(Controller, { control: control, name: 'type_id', render: function (_a) {
                    var field = _a.field;
                    return (_jsx(SelectComponent, __assign({}, field, { error: errors.type_id, placeholder: t('documents.modal.form.placeholders.type'), onChange: field.onChange, label: t('documents.modal.form.labels.type'), selectOptions: selectTypeOptions })));
                } }), _jsx(Controller, { control: control, name: 'available_for', render: function (_a) {
                    var field = _a.field;
                    return (_jsx(MultiSelectComponent, __assign({}, field, { error: errors.available_for, label: t('documents.modal.form.labels.availableFor'), onChange: field.onChange, selectOptions: availableForOptions, placeholder: t('documents.modal.form.placeholders.availableFor'), required: false })));
                } }), _jsxs("div", { className: 'flex flex-col gap-5 md:flex-row justify-between items-center', children: [_jsx(Controller, { control: control, name: 'public_document', render: function (_a) {
                            var field = _a.field;
                            return (_jsx(CheckBox, __assign({}, field, { checked: !!field.value, label: t('documents.modal.form.labels.publicDocument'), onChange: function (e) { return setValue('public_document', e.target.checked); } })));
                        } }), _jsx(ButtonComponent, { type: 'submit', variant: 'primary', children: t('documents.modal.edit.button') })] })] }));
};
