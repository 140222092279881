export var signupPage = {
    title: 'Sign up',
    button: 'Register',
    placeholders: {
        name: 'Name',
        username: 'Username',
        lastname: 'Last name',
        email: 'e-mail',
        password: 'Password',
        repeatPassword: 'Confirm password',
    },
};
