import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, getCurrentDataId } from '../../store/modalSlice';
import { toast } from 'react-toastify';
import { routes } from '../../routes';
import { ButtonComponent } from '../ui';
import { useDeleteUserMutation } from '../../store/usersApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDeleteDocMutation } from '../../store/docsApi';
import { useDeleteFileMutation } from '../../store/filesApi';
export var Delete = function (_a) {
    var type = _a.type;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var id = useSelector(getCurrentDataId);
    var deleteUser = useDeleteUserMutation()[0];
    var deleteDoc = useDeleteDocMutation()[0];
    var deleteFile = useDeleteFileMutation()[0];
    var deleteFunction = {
        user: deleteUser,
        file: deleteFile,
        doc: deleteDoc,
    }[type];
    var question = {
        user: t('users.modal.delete.areYouSure'),
        file: t('files.modal.delete.areYouSure'),
        doc: t('documents.modal.delete.areYouSure'),
    }[type];
    var toasts = {
        user: {
            success: t('users.modal.delete.toast.success'),
            error: t('users.modal.delete.toast.error'),
        },
        file: {
            success: t('files.modal.delete.toast.success'),
            error: t('files.modal.delete.toast.error'),
        },
        doc: {
            success: t('documents.modal.delete.toast.success'),
            error: t('documents.modal.delete.toast.error'),
        },
    }[type];
    var route = {
        user: routes.usersRoute(),
        file: routes.filesRoute(),
        doc: routes.documentsRoute(),
    }[type];
    var handleClose = function () {
        dispatch(closeModal());
    };
    var handleDelete = function () {
        deleteFunction(id)
            .unwrap()
            .then(function () {
            toast.success(toasts.success);
        })
            .catch(function () {
            toast.error(toasts.error);
        });
        dispatch(closeModal());
        navigate(route);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: 'mb-4 font-bold text-center', children: question }), _jsxs("div", { className: 'flex justify-between gap-4', children: [_jsx(ButtonComponent, { variant: 'outline', onClick: handleClose, children: t('cancel') }), _jsx(ButtonComponent, { variant: 'danger', onClick: handleDelete, children: t('delete') })] })] }));
};
