var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import { toast } from 'react-toastify';
import { closeModal, getCurrentDataId } from '../../../store/modalSlice';
import { MultiSelectComponent, CheckBox, ButtonComponent, Title, } from '../../ui';
import { useEditFileMutation, useGetFileQuery as getFile, } from '../../../store/filesApi';
import { routes } from '../../../routes';
import { useGetUsersQuery as getUsers } from '../../../store/usersApi';
export var EditFile = function () {
    var _a;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var id = useSelector(getCurrentDataId);
    var users = getUsers().data;
    var file = getFile(id).data;
    var editFile = useEditFileMutation()[0];
    var defaultValues = {
        available_for: file === null || file === void 0 ? void 0 : file.available_for,
        public_document: file === null || file === void 0 ? void 0 : file.publicEntity,
        type_id: null,
    };
    var _b = useForm({ defaultValues: defaultValues }), control = _b.control, handleSubmit = _b.handleSubmit, errors = _b.formState.errors, setValue = _b.setValue;
    var availableForOptions = (_a = users === null || users === void 0 ? void 0 : users.map(function (user) { return ({
        label: user.name,
        value: user.id,
    }); })) !== null && _a !== void 0 ? _a : [{ label: '', value: 0 }];
    var onSubmit = function (data) {
        if (isEqual(data, defaultValues)) {
            dispatch(closeModal());
        }
        else {
            editFile({ data: __assign(__assign({}, data), { type_id: null }), id: id })
                .unwrap()
                .then(function () {
                toast.success(t('files.modal.edit.toast.success'));
            })
                .catch(function () {
                toast.error(t('files.modal.edit.toast.error'));
            });
            dispatch(closeModal());
            navigate(routes.filesRoute());
        }
    };
    return (_jsxs("form", { className: 'flex flex-col gap-3 sm:gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), children: [_jsx(Title, { children: t('files.modal.title.edit') }), _jsx(Controller, { control: control, name: 'available_for', render: function (_a) {
                    var field = _a.field;
                    return (_jsx(MultiSelectComponent, __assign({}, field, { error: errors.available_for, label: t('files.modal.form.labels.availableFor'), onChange: field.onChange, selectOptions: availableForOptions, placeholder: t('files.modal.form.placeholders.availableFor'), required: false })));
                } }), _jsxs("div", { className: 'flex flex-col gap-5 md:flex-row justify-between items-center', children: [_jsx(Controller, { control: control, name: 'public_document', render: function (_a) {
                            var field = _a.field;
                            return (_jsx(CheckBox, __assign({}, field, { checked: !!field.value, label: t('files.modal.form.labels.publicFile'), onChange: function (e) { return setValue('public_document', e.target.checked); } })));
                        } }), _jsx(ButtonComponent, { type: 'submit', variant: 'primary', children: t('files.modal.edit.button') })] })] }));
};
