var _a;
import { configureStore } from '@reduxjs/toolkit';
import { docsApi } from './docsApi';
import { usersApi } from './usersApi';
import { filesApi } from './filesApi';
import modalSlice from './modalSlice';
var store = configureStore({
    reducer: (_a = {},
        _a[docsApi.reducerPath] = docsApi.reducer,
        _a[usersApi.reducerPath] = usersApi.reducer,
        _a[filesApi.reducerPath] = filesApi.reducer,
        _a.modalSlice = modalSlice,
        _a),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware().concat(docsApi.middleware, usersApi.middleware, filesApi.middleware);
    },
});
export default store;
