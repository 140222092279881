import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useContext } from 'react';
var initialContext = {
    logIn: function () { return undefined; },
    logOut: function () { return undefined; },
    isAuthenticated: false,
    currentUser: {
        roles: [],
        id: null,
        userName: null,
    },
};
export var AuthContext = createContext(initialContext);
var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), isAuthenticated = _b[0], setIsAuthenticated = _b[1];
    var _c = useState({
        roles: [],
        id: null,
        userName: null,
    }), currentUser = _c[0], setCurrentUser = _c[1];
    var logIn = function (currentUser) {
        setCurrentUser(currentUser);
        setIsAuthenticated(true);
    };
    var logOut = function () {
        setIsAuthenticated(false);
    };
    var value = {
        logIn: logIn,
        logOut: logOut,
        isAuthenticated: isAuthenticated,
        currentUser: currentUser,
    };
    return _jsx(AuthContext.Provider, { value: value, children: children });
};
var useAuth = function () { return useContext(AuthContext); };
export { AuthProvider, useAuth };
