import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, getModalOpen, getModalType } from '../../store/modalSlice';
import { CreateDocument } from './document/CreateDocument';
import { EditDocument } from './document/EditDocument';
import { EditUser } from './user/EditUser';
import { EditFile } from './file/EditFile';
import { UploadFile } from './file/UploadFile';
import { ActionButton } from '../ui';
import { Delete } from './Delete';
export var ModalComponent = function () {
    var dispatch = useDispatch();
    var modalType = useSelector(getModalType);
    var open = useSelector(getModalOpen);
    var handleClose = function () {
        dispatch(closeModal());
    };
    return (open && (_jsx("div", { className: 'fixed inset-0 bg-slate-900/60 backdrop-blur overflow-y-auto\n        flex flex-col items-center justify-center', children: _jsxs("div", { "data-id": 'modal', className: 'relative rounded-lg h-fit\n        flex flex-col\n        md:min-w-[500px] \n        p-4 sm:p-6 md:p-8 \n        bg-white dark:bg-secondaryDark', children: [modalType === 'createDocument' && _jsx(CreateDocument, {}), modalType === 'editDocument' && _jsx(EditDocument, {}), modalType === 'editUser' && _jsx(EditUser, {}), modalType === 'editFile' && _jsx(EditFile, {}), modalType === 'uploadFile' && _jsx(UploadFile, {}), modalType === 'deleteFile' && _jsx(Delete, { type: 'file' }), modalType === 'deleteDocument' && _jsx(Delete, { type: 'doc' }), modalType === 'deleteUser' && _jsx(Delete, { type: 'user' }), _jsx(ActionButton, { actionType: 'close', className: 'absolute text-white top-0 left-[100%] mx-2', onClick: handleClose })] }) })));
};
