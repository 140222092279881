import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { useAuth } from './context/AuthContext';
import { SignupPage } from './pages/SignupPage';
import { LoginPage } from './pages/LoginPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { DocumentsPage } from './pages/documents/DocumentsPage';
import { UsersPage } from './pages/users/UsersPage';
import { UserDetailsPage } from './pages/users/UserDetailsPage';
import { FilesPage } from './pages/files/FilesPage';
import { routes } from './routes';
import { Layout } from './components/Layout';
import { SearchPage } from './pages/search/SearchPage';
import FallbackPage from './pages/FallbackPage';
var LazyDocumentDetailsPage = lazy(function () { return import('./pages/documents/DocumentDetailsPage'); });
var LazyFileDetailsPage = lazy(function () { return import('./pages/files/FileDetailsPage'); });
var LoggedInRoute = function () {
    var isAuthenticated = useAuth().isAuthenticated;
    if (isAuthenticated) {
        return _jsx(Outlet, {});
    }
    return _jsx(Navigate, { to: routes.loginRoute() });
};
var LoggedOutRoute = function () {
    var isAuthenticated = useAuth().isAuthenticated;
    if (!isAuthenticated) {
        return _jsx(Outlet, {});
    }
    return _jsx(Navigate, { to: routes.documentsRoute() });
};
var App = function () {
    return (_jsx(BrowserRouter, { children: _jsx(Layout, { children: _jsxs(Routes, { children: [_jsxs(Route, { element: _jsx(LoggedInRoute, {}), children: [_jsx(Route, { path: 'documents/:id', element: _jsx(Suspense, { fallback: _jsx(FallbackPage, {}), children: _jsx(LazyDocumentDetailsPage, {}) }) }), _jsx(Route, { path: 'files/:id', element: _jsx(Suspense, { fallback: _jsx(FallbackPage, {}), children: _jsx(LazyFileDetailsPage, {}) }) }), _jsx(Route, { path: routes.documentsRoute(), element: _jsx(DocumentsPage, {}) }), _jsx(Route, { path: routes.usersRoute(), element: _jsx(UsersPage, {}) }), _jsx(Route, { path: 'users/:id', element: _jsx(UserDetailsPage, {}) }), _jsx(Route, { path: routes.filesRoute(), element: _jsx(FilesPage, {}) }), _jsx(Route, { path: routes.searchRoute(), element: _jsx(SearchPage, {}) })] }), _jsxs(Route, { element: _jsx(LoggedOutRoute, {}), children: [_jsx(Route, { path: routes.signupRoute(), element: _jsx(SignupPage, {}) }), _jsx(Route, { path: routes.loginRoute(), element: _jsx(LoginPage, {}) })] }), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, {}) })] }) }) }));
};
export default App;
