var apiPath = "".concat(process.env.URL, "/api");
export var routes = {
    loginRoute: function () { return '/login'; },
    signupRoute: function () { return '/signup'; },
    searchRoute: function () { return '/search'; },
    documentsRoute: function () { return '/'; },
    documentDetailsRoute: function (id) { return "/documents/".concat(id); },
    usersRoute: function () { return '/users'; },
    userDetailsRoute: function (id) { return "/users/".concat(id); },
    filesRoute: function () { return '/files'; },
    fileDetailsRoute: function (id) { return "/files/".concat(id); },
    loginPath: function () { return [apiPath, 'auth', 'sign-in'].join('/'); },
    signupPath: function () { return [apiPath, 'auth', 'sign-up'].join('/'); },
    thumbnailPath: function (id) { return [apiPath, 'files', "".concat(id, "/thumbnail")].join('/'); },
    fileDownloadPath: function (id) { return [apiPath, 'files', "".concat(id, "?download=true")].join('/'); },
    viewFilePath: function (id) { return [apiPath, 'files', "".concat(id, "?download=false")].join('/'); },
};
