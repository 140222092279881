var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    type: null,
    id: undefined,
    open: false,
};
var modalSlice = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        openModal: function (state, _a) {
            var payload = _a.payload;
            state.type = payload.type;
            state.id = payload.id;
            state.open = true;
        },
        closeModal: function (state) {
            state.open = false;
        },
    },
});
export var openModal = (_a = modalSlice.actions, _a.openModal), closeModal = _a.closeModal;
export var getModalType = function (state) {
    return state.modalSlice.type;
};
export var getModalOpen = function (state) {
    return state.modalSlice.open;
};
export var getCurrentDataId = function (state) {
    return state.modalSlice.id;
};
export default modalSlice.reducer;
