var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonComponent, InputField, ErrorMessage, Title, } from '../components/ui';
import { routes } from '../routes';
export var SignupPage = function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _a = useForm(), register = _a.register, setFocus = _a.setFocus, handleSubmit = _a.handleSubmit, errors = _a.formState.errors, getValues = _a.getValues;
    var _b = useState(false), signupFailed = _b[0], setSignupFailed = _b[1];
    var _c = useState(false), buttonDisabled = _c[0], setButtonDisabled = _c[1];
    var onSubmit = function (data) {
        setSignupFailed(false);
        setButtonDisabled(true);
        fetch(routes.signupPath(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(function (response) {
            if (response.ok) {
                navigate(routes.loginRoute());
            }
            else {
                if (response.status === 400) {
                    setSignupFailed(true);
                    setButtonDisabled(false);
                }
            }
        })
            .catch(function (error) { return console.log(error); });
    };
    useEffect(function () {
        setFocus('username');
    }, [setFocus]);
    return (_jsx("div", { className: 'h-full flex items-center justify-center', children: _jsx("div", { className: 'relative md:min-w-[400px] shadow-lg rounded-md\n        bg-white dark:bg-secondaryDark\n        p-4 sm:p-6 md:p-8', children: _jsxs("form", { className: 'flex flex-col gap-3 sm:gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), noValidate: true, children: [_jsx(Title, { children: t('signupPage.title') }), _jsx(InputField, __assign({ placeholder: t('signupPage.placeholders.username'), error: errors.username }, register('username', {
                        required: { value: true, message: t('errorMessages.reuired') },
                    }))), _jsx(InputField, __assign({ autoComplete: 'on', type: 'email', placeholder: t('signupPage.placeholders.email'), error: errors.email }, register('email', {
                        required: {
                            value: true,
                            message: t('errorMessages.reuired'),
                        },
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t('errorMessages.inValidEmail'),
                        },
                    }))), _jsx(InputField, __assign({ placeholder: t('signupPage.placeholders.name'), error: errors.name }, register('name', {
                        required: { value: true, message: t('errorMessages.reuired') },
                    }))), _jsx(InputField, __assign({ error: errors.lastname, placeholder: t('signupPage.placeholders.lastname') }, register('lastname', {
                        required: { value: true, message: t('errorMessages.reuired') },
                    }))), _jsx(InputField, __assign({ autoComplete: 'new-password', type: 'password', placeholder: t('signupPage.placeholders.password'), error: errors.password }, register('password', {
                        required: {
                            value: true,
                            message: t('errorMessages.reuired'),
                        },
                        minLength: {
                            value: 8,
                            message: t('errorMessages.passwordLength'),
                        },
                        maxLength: {
                            value: 14,
                            message: t('errorMessages.passwordLength'),
                        },
                    }))), _jsx(InputField, __assign({ autoComplete: 'new-password', type: 'password', placeholder: t('signupPage.placeholders.repeatPassword'), error: errors.passwordConfirm }, register('passwordConfirm', {
                        required: {
                            value: true,
                            message: t('errorMessages.reuired'),
                        },
                        validate: function (value) {
                            var password = getValues('password');
                            return value === password || t('errorMessages.confirmPassword');
                        },
                    }))), signupFailed && (_jsx(ErrorMessage, { className: 'bottom-16', children: t('errorMessages.userExists') })), _jsx(ButtonComponent, { disabled: buttonDisabled, type: 'submit', variant: 'primary', children: t('signupPage.button') })] }) }) }));
};
