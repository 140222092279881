export var files = {
    title: 'Список файлов',
    create: 'Добавить файл',
    searchPlaceholder: 'Файл или пользователь',
    quantity: {
        count_one: '{{count}} файл',
        count_few: '{{count}} файла',
        count_many: '{{count}} файлов',
    },
    tableHeader: {
        fileName: 'Название',
        author: 'Автор',
        fileType: 'Тип',
        creationDate: 'Дата создания',
        updateDate: 'Дата обновления',
        actions: 'Действия',
    },
    detailsPage: {
        title: 'Файл',
        name: 'Название: ',
        author: 'Автор: ',
        type: 'Тип: ',
        creationDate: 'Дата создания: ',
        delete: 'Удалить файл',
        edit: 'Изменить файл',
    },
    modal: {
        title: {
            edit: 'Редактирование доступа к файлу',
            create: 'Загрузка файла',
        },
        form: {
            labels: {
                availableFor: 'Сделать доступным для:',
                publicFile: 'Сделать файл публичным',
                addFile: 'Выберите файл',
            },
            placeholders: {
                availableFor: 'Выберите пользователей',
            },
        },
        delete: {
            areYouSure: 'Вы уверены, что хотите удалить этот файл?',
            toast: {
                error: 'Произошла ошибка',
                success: 'Файл удален',
            },
        },
        upload: {
            toast: {
                error: 'Произошла ошибка',
                success: 'Файл добавлен',
            },
            button: 'Загрузить файл',
        },
        edit: {
            toast: {
                error: 'Произошла ошибка',
                success: 'Доступ к файлу изменен',
            },
            button: 'Сохранить изменения',
        },
    },
};
