var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputField, TextArea, SelectComponent, CheckBox, ButtonComponent, MultiSelectComponent, Title, } from '../../ui';
import { useCreateDocMutation } from '../../../store/docsApi';
import { useAuth } from '../../../context/AuthContext';
import { useGetUsersQuery as getUsers } from '../../../store/usersApi';
import { routes } from '../../../routes';
import { closeModal } from '../../../store/modalSlice';
import { createDocFormSchema } from './docFormSchema';
import { useEffect } from 'react';
export var CreateDocument = function () {
    var _a;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var currentUser = useAuth().currentUser;
    var selectTypeOptions = [
        { value: 1, label: t('documents.type.NOTE') },
        { value: 2, label: t('documents.type.REPORT') },
        { value: 3, label: t('documents.type.PRESENTATION') },
        { value: 4, label: t('documents.type.ARTICLE') },
    ];
    var _b = useForm({
        defaultValues: { public_document: false, available_for: [] },
        resolver: zodResolver(createDocFormSchema),
    }), register = _b.register, control = _b.control, handleSubmit = _b.handleSubmit, errors = _b.formState.errors, setValue = _b.setValue, setFocus = _b.setFocus;
    var createDoc = useCreateDocMutation()[0];
    var users = getUsers().data;
    var options = (_a = users === null || users === void 0 ? void 0 : users.map(function (user) { return ({
        label: user.name,
        value: user.id,
    }); })) !== null && _a !== void 0 ? _a : [{ label: '', value: 0 }];
    var onSubmit = function (data) {
        createDoc(__assign(__assign({}, data), { authorId: currentUser.id }))
            .unwrap()
            .then(function () {
            toast.success(t('documents.modal.create.toast.success'));
        })
            .catch(function () {
            toast.error(t('documents.modal.create.toast.error'));
        });
        dispatch(closeModal());
        navigate(routes.documentsRoute());
    };
    useEffect(function () {
        setFocus('title');
    }, [setFocus]);
    return (_jsxs("form", { className: 'flex flex-col gap-3 sm:gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), children: [_jsx(Title, { children: t('documents.modal.title.create') }), _jsx(InputField, __assign({}, register('title'), { label: t('documents.modal.form.labels.title'), error: errors.title })), _jsx(InputField, __assign({}, register('number'), { label: t('documents.modal.form.labels.number'), placeholder: t('documents.modal.form.placeholders.number'), error: errors.number, type: 'number' })), _jsx(TextArea, __assign({}, register('content'), { label: t('documents.modal.form.labels.content'), error: errors.content })), _jsx(Controller, { control: control, name: 'type_id', render: function (_a) {
                    var field = _a.field;
                    return (_jsx(SelectComponent, __assign({}, field, { placeholder: t('documents.modal.form.placeholders.type'), error: errors.type_id, label: t('documents.modal.form.labels.type'), onChange: field.onChange, selectOptions: selectTypeOptions })));
                } }), _jsx(Controller, { control: control, name: 'available_for', render: function (_a) {
                    var field = _a.field;
                    return (_jsx(MultiSelectComponent, __assign({}, field, { error: errors.available_for, placeholder: t('documents.modal.form.placeholders.availableFor'), label: t('documents.modal.form.labels.availableFor'), onChange: field.onChange, selectOptions: options, required: false })));
                } }), _jsxs("div", { className: 'flex flex-col gap-5 md:flex-row justify-between items-center', children: [_jsx(CheckBox, __assign({ label: t('documents.modal.form.labels.publicDocument') }, register('public_document'), { onChange: function (e) { return setValue('public_document', e.target.checked); } })), _jsx(ButtonComponent, { type: 'submit', variant: 'primary', children: t('documents.modal.create.button') })] })] }));
};
