export var signupPage = {
    title: 'Регистрация пользователя',
    button: 'Зарегистрироваться',
    placeholders: {
        name: 'Имя',
        username: 'Имя пользователя',
        lastname: 'Фамилия',
        email: 'e-mail',
        password: 'Пароль',
        repeatPassword: 'Подтвердите пароль',
    },
};
