import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Switcher } from '../ui';
import { MoonIcon, SunIcon } from '../ui/icons';
import { useTheme } from '../../context/ThemeContext';
export var ThemeSwitcher = function (_a) {
    var className = _a.className;
    var _b = useTheme(), theme = _b.theme, setDarkTheme = _b.setDarkTheme, setLightTheme = _b.setLightTheme;
    var _c = useState(theme == 'dark'), isToggled = _c[0], setIsToggled = _c[1];
    var handleChangeTheme = function () {
        setIsToggled(function (state) { return !state; });
    };
    useEffect(function () {
        if (isToggled)
            setDarkTheme();
        if (!isToggled)
            setLightTheme();
    }, [isToggled]);
    return (_jsx(Switcher, { className: className, isToggled: isToggled, onToggle: handleChangeTheme, icons: {
            left: _jsx(SunIcon, { className: 'absolute text-white z-20 left-0.5 top-0.5' }),
            right: (_jsx(MoonIcon, { className: 'absolute text-whiteDark z-20 right-0.5 top-[3px]' })),
        } }));
};
