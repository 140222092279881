import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
export var Card = function (_a) {
    var children = _a.children, className = _a.className;
    return (_jsx("div", { className: clsx(className, "md:w-1/2 p-4 rounded-md shadow-lg \n    bg-white dark:bg-secondaryDark"), children: children }));
};
Card.Header = function (_a) {
    var children = _a.children;
    return (_jsx("h1", { className: 'font-bold text-center uppercase', children: children }));
};
Card.Body = function (_a) {
    var children = _a.children, className = _a.className;
    return _jsx("div", { className: clsx(className, 'mt-5'), children: children });
};
Card.Footer = function (_a) {
    var children = _a.children;
    return (_jsx("div", { className: 'flex flex-col xs:flex-row justify-between gap-1 mt-5 bg-white dark:bg-secondaryDark', children: children }));
};
