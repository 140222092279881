import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
export var QuantityTag = function (_a) {
    var number = _a.number, type = _a.type;
    var t = useTranslation().t;
    var info = {
        files: t('files.quantity.count', { count: number }),
        documents: t('documents.quantity.count', { count: number }),
        users: t('users.quantity.count', { count: number }),
    }[type];
    return (_jsx("div", { className: 'h-9 md:h-10 p-2 rounded-sm leading-none box-border flex items-center text-nowrap\n      text-secondary dark:text-primaryDark\n      bg-highlightDark dark:bg-highlight', children: info }));
};
