import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { DropDown } from '../ui';
export var UserButton = function () {
    var _a = useAuth(), logOut = _a.logOut, currentUser = _a.currentUser;
    var t = useTranslation().t;
    var options = {
        logout: t('header.logout'),
    };
    var handleLogOut = function () {
        logOut();
    };
    return (_jsx(DropDown, { name: currentUser.userName, options: options, action: handleLogOut }));
};
