var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo } from 'react';
var range = function (start, end) {
    var length = end - start + 1;
    return Array.from({ length: length }, function (_, idx) { return idx + start; });
};
export var usePagination = function (_a) {
    var numberOfPages = _a.numberOfPages, _b = _a.numberOfSiblings, numberOfSiblings = _b === void 0 ? 2 : _b, currentPage = _a.currentPage;
    var paginationRange = useMemo(function () {
        var numberOfButtons = 2 * numberOfSiblings + 1;
        if (numberOfPages < numberOfButtons) {
            return range(1, numberOfPages);
        }
        var firstButton = numberOfPages - currentPage > numberOfSiblings
            ? Math.max(currentPage - numberOfSiblings, 1)
            : Math.min(currentPage - numberOfSiblings, numberOfPages - numberOfSiblings * 2);
        var numberOfLeftButtons = Math.min(currentPage - firstButton, numberOfSiblings);
        var numberOfRightButtons = numberOfSiblings * 2 - numberOfLeftButtons;
        var lastButton = Math.min(currentPage + numberOfRightButtons, numberOfPages);
        return __spreadArray(__spreadArray([], range(firstButton, currentPage), true), range(currentPage + 1, lastButton), true);
    }, [numberOfPages, numberOfSiblings, currentPage]);
    return paginationRange;
};
