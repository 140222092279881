import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export var docsApi = createApi({
    reducerPath: 'documents',
    baseQuery: fetchBaseQuery({
        baseUrl: "".concat(process.env.URL, "/api/documents"),
        credentials: 'include',
    }),
    tagTypes: ['docs', 'doc'],
    endpoints: function (builder) { return ({
        getDocs: builder.query({
            query: function (roles) {
                if (roles.includes('ROLE_ADMIN') || roles.includes('ROLE_MODERATOR')) {
                    return { url: '' };
                }
                return { url: '/for_user' };
            },
            providesTags: ['docs'],
        }),
        getDoc: builder.query({
            query: function (id) { return ({
                url: "/".concat(id),
            }); },
            providesTags: ['doc'],
        }),
        createDoc: builder.mutation({
            query: function (data) { return ({
                url: '',
                method: 'POST',
                body: data,
            }); },
            invalidatesTags: ['docs'],
        }),
        deleteDoc: builder.mutation({
            query: function (id) { return ({
                url: "/".concat(id),
                method: 'DELETE',
            }); },
            invalidatesTags: ['docs'],
        }),
        editDoc: builder.mutation({
            query: function (_a) {
                var data = _a.data, id = _a.id;
                return ({
                    url: "/for_admin/".concat(id),
                    method: 'PUT',
                    body: data,
                });
            },
            invalidatesTags: ['docs', 'doc'],
        }),
        searchDoc: builder.query({
            //pageNumber
            query: function (params) { return ({
                url: "/search?".concat(params),
            }); },
        }),
    }); },
});
export var useGetDocsQuery = docsApi.useGetDocsQuery, useGetDocQuery = docsApi.useGetDocQuery, useDeleteDocMutation = docsApi.useDeleteDocMutation, useCreateDocMutation = docsApi.useCreateDocMutation, useEditDocMutation = docsApi.useEditDocMutation;
