import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export var usersApi = createApi({
    reducerPath: 'users',
    baseQuery: fetchBaseQuery({
        baseUrl: "".concat(process.env.URL, "/api/users"),
        credentials: 'include',
    }),
    tagTypes: ['users', 'user'],
    endpoints: function (builder) { return ({
        getUsers: builder.query({
            query: function () { return ({
                url: '',
            }); },
            providesTags: ['users'],
        }),
        getUser: builder.query({
            query: function (id) { return ({
                url: "/".concat(id),
            }); },
            providesTags: ['user'],
        }),
        deleteUser: builder.mutation({
            query: function (id) { return ({
                url: "/".concat(id),
                method: 'DELETE',
            }); },
            invalidatesTags: ['users'],
        }),
        editUser: builder.mutation({
            query: function (_a) {
                var id = _a.id, data = _a.data;
                return ({
                    url: "/for-admin/".concat(id),
                    method: 'PUT',
                    body: data,
                });
            },
            invalidatesTags: ['users', 'user'],
        }),
    }); },
});
export var useGetUsersQuery = usersApi.useGetUsersQuery, useGetUserQuery = usersApi.useGetUserQuery, useDeleteUserMutation = usersApi.useDeleteUserMutation, useEditUserMutation = usersApi.useEditUserMutation;
