import { jsx as _jsx } from "react/jsx-runtime";
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { resources } from './locales/resources';
import App from './App';
import { ThemeProvider } from './context/ThemeContext';
import { AuthProvider } from './context/AuthContext';
import store from './store';
import ErrorBoundary from './components/ErrorBoundary';
import { ErrorPage } from './pages/ErrorPage';
export var init = function () {
    var i18n = i18next.createInstance();
    i18n.use(initReactI18next).init({
        resources: resources,
        lng: localStorage.getItem('language') || 'ru',
        fallbackLng: 'ru',
        interpolation: {
            escapeValue: false,
        },
        debug: true,
    });
    return (_jsx(I18nextProvider, { i18n: i18n, children: _jsx(ErrorBoundary, { fallback: _jsx(ErrorPage, {}), children: _jsx(Provider, { store: store, children: _jsx(AuthProvider, { children: _jsx(ThemeProvider, { children: _jsx(App, {}) }) }) }) }) }));
};
