import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { SelectComponent } from '../ui';
import clsx from 'clsx';
export var PageSizeSwitcher = function (_a) {
    var onChange = _a.onChange, value = _a.value, className = _a.className;
    var t = useTranslation().t;
    var selectOptions = [
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 20, label: '20' },
    ];
    return (_jsxs("div", { className: clsx(className, 'flex gap-2 items-center text-secondary dark:text-whiteDark'), children: [_jsx("div", { children: t('show') }), _jsx(SelectComponent, { className: 'w-20', required: false, onChange: onChange, selectOptions: selectOptions, value: value })] }));
};
