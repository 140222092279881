var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ButtonComponent, Card } from '../../components/ui';
import { useGetUserQuery as getUser } from '../../store/usersApi';
import { Spinner } from '../../components/ui/icons';
import { openModal } from '../../store/modalSlice';
import { useAuth } from '../../context/AuthContext';
import { defineAbilityFor } from '../../casl/ability';
import { Can } from '@casl/react';
export var UserDetailsPage = function () {
    var id = useParams().id;
    var _a = useAuth(), currentUser = _a.currentUser, isAuthenticated = _a.isAuthenticated;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var _b = getUser(id), user = _b.data, isLoading = _b.isLoading;
    var ability = defineAbilityFor({
        user: __assign(__assign({}, currentUser), { isAuthenticated: isAuthenticated }),
        entity: { authorId: user === null || user === void 0 ? void 0 : user.id },
    });
    var handleDelete = function () {
        dispatch(openModal({ type: 'deleteUser', open: true, id: id }));
    };
    var handleEdit = function () {
        dispatch(openModal({ type: 'editUser', open: true, id: id }));
    };
    if (isLoading) {
        return _jsx(Spinner, { className: 'h-[100%]' });
    }
    return (_jsxs(Card, { children: [_jsxs(Card.Header, { children: [t('users.detailsPage.title') + ' ', _jsx("span", { className: 'text-highlight dark:text-highlightDark', children: user === null || user === void 0 ? void 0 : user.username })] }), _jsxs(Card.Body, { children: [_jsxs("div", { children: [_jsx("span", { className: 'font-bold', children: t('users.detailsPage.name') }), user === null || user === void 0 ? void 0 : user.name] }), _jsxs("div", { children: [_jsx("span", { className: 'font-bold', children: t('users.detailsPage.lastName') }), user === null || user === void 0 ? void 0 : user.lastname] }), _jsxs("div", { children: [_jsx("span", { className: 'font-bold', children: t('users.detailsPage.email') }), user === null || user === void 0 ? void 0 : user.email] }), _jsxs("div", { children: [_jsx("span", { className: 'font-bold', children: t('users.detailsPage.roles') }), user === null || user === void 0 ? void 0 : user.roles.map(function (role) { return t("users.roles.".concat(role.name)); }).join(', ')] })] }), _jsxs(Card.Footer, { children: [_jsx(Can, { I: 'edit', a: 'user', ability: ability, children: _jsx(ButtonComponent, { variant: 'primary', onClick: handleEdit, children: t('users.detailsPage.edit') }) }), _jsx(Can, { I: 'delete', a: 'user', ability: ability, children: _jsx(ButtonComponent, { variant: 'danger', onClick: handleDelete, children: t('users.detailsPage.delete') }) })] })] }));
};
