var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InputField, CheckBox, ButtonComponent, Title } from '../../ui';
import { useEditUserMutation, useGetUserQuery as getUser, } from '../../../store/usersApi';
import { closeModal, getCurrentDataId } from '../../../store/modalSlice';
import { routes } from '../../../routes';
import { useAuth } from '../../../context/AuthContext';
import { defineAbilityFor } from '../../../casl/ability';
import { Can } from '@casl/react';
var roles = [
    { label: 'Администратор', value: 1 },
    { label: 'Пользователь', value: 3 },
    { label: 'Модератор', value: 2 },
];
export var EditUser = function () {
    var t = useTranslation().t;
    var _a = useAuth(), currentUser = _a.currentUser, isAuthenticated = _a.isAuthenticated;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var id = useSelector(getCurrentDataId);
    var user = getUser(id).data;
    var editUser = useEditUserMutation()[0];
    var ability = defineAbilityFor({
        user: __assign(__assign({}, currentUser), { isAuthenticated: isAuthenticated }),
    });
    var defaultValues = {
        username: user === null || user === void 0 ? void 0 : user.username,
        email: user === null || user === void 0 ? void 0 : user.email,
        name: user === null || user === void 0 ? void 0 : user.name,
        lastName: user === null || user === void 0 ? void 0 : user.lastname,
        role_ids: user === null || user === void 0 ? void 0 : user.roles.map(function (role) { return role.idRole; }),
    };
    var _b = useForm({ defaultValues: defaultValues }), register = _b.register, control = _b.control, handleSubmit = _b.handleSubmit, getValues = _b.getValues, setValue = _b.setValue;
    var onSubmit = function (data) {
        editUser({ data: data, id: id })
            .unwrap()
            .then(function () {
            toast.success(t('users.modal.edit.toast.success'));
        })
            .catch(function () {
            toast.error(t('users.modal.edit.toast.error'));
        });
        dispatch(closeModal());
        navigate(routes.userDetailsRoute(id));
    };
    var handleCheck = function (e) {
        var _a = e.target, checked = _a.checked, value = _a.value;
        var roles = getValues('role_ids');
        if (checked) {
            setValue('role_ids', __spreadArray(__spreadArray([], roles, true), [Number(value)], false));
        }
        else {
            setValue('role_ids', roles.filter(function (role) { return role !== Number(value); }));
        }
    };
    return (_jsxs("form", { className: 'flex flex-col gap-3 sm:gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), children: [_jsx(Title, { children: t('users.modal.title.edit') }), _jsx(InputField, __assign({ label: t('users.modal.form.labels.username') }, register('username'))), _jsx(InputField, __assign({ label: t('users.modal.form.labels.email') }, register('email'))), _jsx(InputField, __assign({ label: t('users.modal.form.labels.name') }, register('name'))), _jsx(InputField, __assign({ label: t('users.modal.form.labels.lastname') }, register('lastName'))), _jsx(Can, { I: 'edit', a: 'role', ability: ability, children: _jsx("fieldset", { children: roles.map(function (role) { return (_jsx(Controller, { control: control, name: 'role_ids', render: function (_a) {
                            var _b;
                            var field = _a.field;
                            return (_jsx(CheckBox, __assign({}, field, { label: role.label, checked: (_b = field.value) === null || _b === void 0 ? void 0 : _b.includes(role.value), value: role.value, onChange: function (e) { return handleCheck(e); } })));
                        } }, role.value)); }) }) }), _jsx(ButtonComponent, { type: 'submit', variant: 'primary', children: t('users.modal.edit.button') })] }));
};
