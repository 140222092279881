import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { ButtonComponent } from './ButtonComponent';
import clsx from 'clsx';
import { useClickOutside } from '../../hooks';
export var DropDown = function (_a) {
    var name = _a.name, options = _a.options, action = _a.action, className = _a.className;
    var _b = useState(false), menuOpen = _b[0], setMenuOpen = _b[1];
    var ref = useRef(null);
    var handleChooseOption = function (e) {
        var param = e.target.dataset.param;
        setMenuOpen(false);
        action(param);
    };
    var handleClickOutside = function () {
        setMenuOpen(false);
    };
    var handleOpenMenu = function () {
        setMenuOpen(function (state) { return !state; });
    };
    useClickOutside(ref, handleClickOutside);
    return (_jsxs("div", { className: clsx(className, 'relative'), children: [_jsx(ButtonComponent, { variant: 'outline', onClick: handleOpenMenu, children: name }), _jsx("div", { ref: ref, className: clsx(menuOpen ? 'block' : 'hidden', 'absolute right-0 top-10'), children: Object.keys(options).map(function (option) { return (_jsx(ButtonComponent, { variant: 'borderLess', className: 'w-full text-right rounded-none', onClick: function (e) { return handleChooseOption(e); }, "data-param": option, children: options[option] }, option)); }) })] }));
};
