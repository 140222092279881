import { jsx as _jsx } from "react/jsx-runtime";
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useMemo } from 'react';
export var EmotionCacheProvider = function (_a) {
    var children = _a.children;
    var cache = useMemo(function () {
        return createCache({
            key: 'with-tailwind',
            insertionPoint: document.querySelector('title'),
        });
    }, []);
    return _jsx(CacheProvider, { value: cache, children: children });
};
