var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonComponent, InputField, ErrorMessage, Title, ActionButton, } from '../components/ui';
import { routes } from '../routes';
import { useAuth } from '../context/AuthContext';
export var LoginPage = function () {
    var t = useTranslation().t;
    var _a = useForm(), register = _a.register, setFocus = _a.setFocus, handleSubmit = _a.handleSubmit, errors = _a.formState.errors;
    var logIn = useAuth().logIn;
    var navigate = useNavigate();
    var _b = useState(false), buttonDisabled = _b[0], setButtonDisabled = _b[1];
    var _c = useState(false), authFailed = _c[0], setauthFailed = _c[1];
    var _d = useState('password'), passwordInputType = _d[0], setInputType = _d[1];
    var _e = useState(false), passwordShown = _e[0], setPasswordShown = _e[1];
    var toggleShowPassword = function () {
        setPasswordShown(function (prevState) { return !prevState; });
        setInputType(function (prevState) {
            return prevState === 'password' ? 'text' : 'password';
        });
    };
    var onSubmit = function (data) {
        setauthFailed(false);
        setButtonDisabled(true);
        fetch(routes.loginPath(), {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(function (response) { return response.json(); })
            .then(function (data) {
            if (data.user) {
                var roles = data.user.roles.map(function (role) { return role.name; });
                var id = data.user.idUser;
                var userName = data.user.username;
                logIn({ roles: roles, id: id, userName: userName });
                navigate(routes.documentsRoute());
            }
            else {
                if (data.status === 400) {
                    setauthFailed(true);
                    setButtonDisabled(false);
                }
            }
        })
            .catch(function (error) {
            console.log(error);
        });
    };
    useEffect(function () {
        setFocus('username');
    }, [setFocus]);
    return (_jsx("div", { className: 'h-full flex items-center justify-center', children: _jsx("div", { className: 'relative md:min-w-[400px] shadow-lg rounded-md\n        bg-white dark:bg-secondaryDark\n        p-4 sm:p-6 md:p-8', children: _jsxs("form", { className: 'flex flex-col gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), children: [_jsx(Title, { children: t('loginPage.title') }), _jsx(InputField, __assign({ autoComplete: 'on', placeholder: t('loginPage.placeholders.userName'), error: errors.username }, register('username', {
                        required: {
                            value: true,
                            message: t('errorMessages.reuired'),
                        },
                    }))), _jsx(InputField, __assign({ autoComplete: 'on', type: passwordInputType, placeholder: t('loginPage.placeholders.password'), error: errors.password }, register('password', {
                        required: {
                            value: true,
                            message: t('errorMessages.reuired'),
                        },
                        minLength: {
                            value: 8,
                            message: t('errorMessages.passwordLength'),
                        },
                        maxLength: {
                            value: 14,
                            message: t('errorMessages.passwordLength'),
                        },
                    }), { actionButton: _jsx(ActionButton, { className: 'absolute top-2 right-3', onClick: toggleShowPassword, actionType: passwordShown ? 'hidePassword' : 'showPassword' }) })), authFailed && !errors.password && (_jsx(ErrorMessage, { className: 'bottom-14 sm:bottom-16 md:bottom-[70px]', children: t('errorMessages.wrongPasswordOrUsername') })), _jsx(ButtonComponent, { disabled: buttonDisabled, type: 'submit', variant: 'primary', className: 'mt-4 md:mt-0', children: t('loginPage.button') })] }) }) }));
};
