import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Title } from '../../components/ui';
import { useGetFilesQuery as getFiles } from '../../store/filesApi';
import { routes } from '../../routes';
import { useAuth } from '../../context/AuthContext';
import { openModal } from '../../store/modalSlice';
import { TableContainer } from '../../components/TableContainer';
import { Spinner } from '../../components/ui/icons';
export var FilesPage = function () {
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var currentUser = useAuth().currentUser;
    var _a = getFiles(currentUser.roles), files = _a.data, isLoading = _a.isLoading;
    var tableColumns = [
        {
            label: t('files.tableHeader.fileName'),
            accessor: 'name',
            sortable: true,
        },
        {
            label: t('files.tableHeader.fileType'),
            accessor: 'type',
            sortable: false,
        },
        {
            label: t('files.tableHeader.author'),
            accessor: 'author',
            sortable: true,
        },
        {
            label: t('files.tableHeader.creationDate'),
            accessor: 'creationDate',
            sortable: true,
        },
        {
            label: t('files.tableHeader.updateDate'),
            accessor: 'updateDate',
            sortable: true,
        },
    ];
    var tableData = files === null || files === void 0 ? void 0 : files.map(function (file) { return ({
        id: file.id,
        data: {
            name: file.filename,
            type: file.filetype,
            author: file.author,
            creationDate: file.creationDate
                ? Date.parse(file.creationDate)
                : 'no data',
            updateDate: file.updateDate ? Date.parse(file.updateDate) : 'no data',
        },
    }); });
    var handleCreate = function () {
        dispatch(openModal({ type: 'uploadFile', open: true }));
    };
    var handleGoToDetailsPage = function (id) {
        navigate(routes.fileDetailsRoute(id));
    };
    if (isLoading) {
        return _jsx(Spinner, { className: 'h-full' });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: t('files.title') }), _jsx(TableContainer, { tableColumns: tableColumns, tableData: tableData, handleGoToDetailsPage: handleGoToDetailsPage, type: 'files', handleCreate: handleCreate, className: 'mt-4' })] }));
};
