import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '../../routes';
import { useGetUsersQuery as getUsers } from '../../store/usersApi';
import { Spinner } from '../../components/ui/icons';
import { Title } from '../../components/ui';
import { TableContainer } from '../../components/TableContainer';
export var UsersPage = function () {
    var t = useTranslation().t;
    var _a = getUsers(), users = _a.data, isLoading = _a.isLoading;
    var navigate = useNavigate();
    var tableColumns = [
        {
            label: t('users.tableHeader.userName'),
            accessor: 'userName',
            sortable: true,
        },
        {
            label: t('users.tableHeader.name'),
            accessor: 'name',
            sortable: true,
        },
        {
            label: t('users.tableHeader.lastName'),
            accessor: 'lastName',
            sortable: true,
        },
        {
            label: t('users.tableHeader.roles'),
            accessor: 'roles',
            sortable: false,
        },
    ];
    var tableData = users === null || users === void 0 ? void 0 : users.map(function (user) { return ({
        id: user.id,
        data: {
            userName: user.username,
            name: user.name,
            lastName: user.lastname,
            roles: user.roles.map(function (role) { return t("users.roles.".concat(role.name)); }).join(', '),
        },
    }); });
    var handleGoToDetailsPage = function (id) {
        navigate(routes.userDetailsRoute(id));
    };
    if (isLoading) {
        return _jsx(Spinner, { className: 'h-full' });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: t('users.title') }), _jsx(TableContainer, { tableColumns: tableColumns, tableData: tableData, handleGoToDetailsPage: handleGoToDetailsPage, type: 'users', className: 'mt-4' })] }));
};
