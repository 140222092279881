var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef, } from 'react';
import { DeleteIcon, EditIcon, EyeIcon, CrossedEyeIcon, CloseIcon, DownloadIcon, OverviewIcon, MenuIcon, SearchIcon, ChevronSingle, ChevronDouble, } from './icons';
export var ActionButton = forwardRef(function (_a, ref) {
    var actionType = _a.actionType, className = _a.className, _b = _a.mirrored, mirrored = _b === void 0 ? false : _b, children = _a.children, props = __rest(_a, ["actionType", "className", "mirrored", "children"]);
    var Icon = {
        chevronSingle: _jsx(ChevronSingle, {}),
        chevronDouble: _jsx(ChevronDouble, {}),
        character: children,
        delete: _jsx(DeleteIcon, {}),
        edit: _jsx(EditIcon, {}),
        showPassword: _jsx(EyeIcon, {}),
        hidePassword: _jsx(CrossedEyeIcon, {}),
        close: _jsx(CloseIcon, {}),
        download: _jsx(DownloadIcon, {}),
        overview: _jsx(OverviewIcon, {}),
        openMenu: _jsx(MenuIcon, {}),
        search: _jsx(SearchIcon, {}),
    }[actionType];
    return (_jsx("button", __assign({ type: 'button' }, props, { className: clsx(className, mirrored && 'rotate-180', 'text-secondary hover:text-secondaryHover dark:text-whiteDark dark:hover:text-whiteDarkHover font-bold transition-colors', 'text-xs sm:text-sm md:text-md'), ref: ref, children: Icon })));
});
