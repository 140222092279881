var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, } from 'react';
export var CheckBox = forwardRef(function (_a, ref) {
    var label = _a.label, value = _a.value, checked = _a.checked, onChange = _a.onChange, props = __rest(_a, ["label", "value", "checked", "onChange"]);
    return (_jsxs("label", { htmlFor: label, className: 'flex gap-1 justify-between items-center', children: [label, _jsx("input", __assign({ id: label, type: 'checkbox', onChange: onChange, checked: checked, value: value, className: 'absolute opacity-0 h-0 w-0 peer', ref: ref }, props)), _jsx("span", { className: "relative inline-block h-4 w-4 border border:white dark:border:whiteDark rounded-sm\n        after:content-[''] after:absolute after:hidden after:bottom-1 after:left-0.5 after:w-3 after:h-5\n        after:border-r-[3px] after:border-b-[3px] after:border-highlight after:rotate-45 after:z-10\n        dark:after:border-highlightDark\n        peer-checked:after:block" })] }));
});
