import { ru } from './ru';
import { en } from './en';
export var resources = {
    ru: {
        translation: ru,
    },
    en: {
        translation: en,
    },
};
