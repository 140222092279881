var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from 'react-select';
import { InputLabel } from '../InputLabel';
import { ErrorMessage } from '../ErrorMessage';
import { EmotionCacheProvider } from './EmotionProvider';
import { classNames } from './styles';
import clsx from 'clsx';
import { forwardRef } from 'react';
export var SelectComponent = forwardRef(function (_a, ref) {
    var onChange = _a.onChange, placeholder = _a.placeholder, selectOptions = _a.selectOptions, label = _a.label, error = _a.error, value = _a.value, _b = _a.required, required = _b === void 0 ? true : _b, className = _a.className, props = __rest(_a, ["onChange", "placeholder", "selectOptions", "label", "error", "value", "required", "className"]);
    var handleSelect = function (option) {
        var _option = option;
        onChange(_option.value);
    };
    return (_jsx(EmotionCacheProvider, { children: _jsxs("div", { className: clsx(className, 'relative'), children: [_jsx(InputLabel, { required: required, children: label }), _jsx(Select, __assign({ value: value
                        ? selectOptions.find(function (option) { return option.value === value; })
                        : undefined, classNames: classNames, onChange: handleSelect, options: selectOptions, placeholder: placeholder, ref: ref }, props)), error && _jsx(ErrorMessage, { children: error.message })] }) }));
});
