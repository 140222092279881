import { useState } from "react";
export var useFilteredTable = function (rows, fields) {
    var _a = useState(rows), filteredTable = _a[0], setFilteredTable = _a[1];
    var filterTable = function (filterValue) {
        if (filterValue) {
            var filteredRows = rows.filter(function (row) {
                if (row.data) {
                    return fields.some(function (field) {
                        if (row.data[field]) {
                            return row.data[field].toLowerCase().includes(filterValue);
                        }
                    });
                }
                return false;
            });
            setFilteredTable(filteredRows);
        }
        else {
            setFilteredTable(rows);
        }
    };
    return [filteredTable, filterTable];
};
