var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState } from "react";
export var useSortableTable = function (tableData) {
    var _a = useState(tableData), sortedData = _a[0], setSortedData = _a[1];
    var sortTable = function (sortField, sortOrder) {
        if (sortField && tableData) {
            var sorted = __spreadArray([], tableData, true).sort(function (a, b) {
                if (!a['data'][sortField])
                    return 1;
                if (!b['data'][sortField])
                    return -1;
                if (a['data'][sortField] && b['data'][sortField]) {
                    return (a.data[sortField].toString().localeCompare(b.data[sortField].toString(), ["en", 'ru'], {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1));
                }
                return 0;
            });
            setSortedData(sorted);
        }
    };
    return [sortedData, sortTable];
};
