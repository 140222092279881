import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
var initialContext = {
    theme: 'light',
    setDarkTheme: function () { return undefined; },
    setLightTheme: function () { return undefined; },
};
export var ThemeContext = createContext(initialContext);
var ThemeProvider = function (_a) {
    var children = _a.children;
    var _b = useLocalStorage('theme', 'light'), theme = _b[0], setTheme = _b[1];
    var setDarkTheme = function () {
        setTheme('dark');
    };
    var setLightTheme = function () {
        setTheme('light');
    };
    useEffect(function () {
        document.body.classList.remove('light', 'dark');
        document.body.classList.add(theme);
    }, [theme]);
    var value = {
        theme: theme,
        setDarkTheme: setDarkTheme,
        setLightTheme: setLightTheme,
    };
    return (_jsx(ThemeContext.Provider, { value: value, children: children }));
};
var useTheme = function () { return useContext(ThemeContext); };
export { ThemeProvider, useTheme };
